import {
  query,
  createAsync,
  RouteDefinition,
  useParams,
} from "@solidjs/router";
import { Match, Show, Switch } from "solid-js";
import {
  addToWaitlist,
  deleteEmailVerification,
  getEmailFromToken,
} from "~/lib/db/audience";
import XCircle from "lucide-solid/icons/x-circle";
import CheckCircle from "lucide-solid/icons/check-circle";
import Loader2 from "lucide-solid/icons/loader-2";
import House from "lucide-solid/icons/house";

const statusIcons = {
  loading: <Loader2 class="w-16 h-16 text-blue-500 animate-spin" />,
  ok: <CheckCircle class="w-16 h-16 text-green-500" />,
  error: <XCircle class="w-16 h-16 text-red-500" />,
};

const verifyEmail = query(async (token: string) => {
  "use server";

  const verityfingEmail = await getEmailFromToken(token);

  if (typeof verityfingEmail === "string") {
    await addToWaitlist(verityfingEmail);
    await deleteEmailVerification(token);

    return "ok";
  } else {
    return "error";
  }
}, "verify-email");

export const route = {
  preload: ({ params }) => {
    const { token } = params;
    return verifyEmail(token);
  },
} satisfies RouteDefinition;

export default function Verify() {
  const status = createAsync<"ok" | "error" | "loading">(
    () => verifyEmail(useParams().token),
    {
      initialValue: "loading",
    }
  );

  return (
    <div class="h-screen w-screen grid place-items-center">
      <div
        class={`max-w-md w-full border border-sky-950/40 rounded-2xl p-8 shadow-lg shadow-sky-800/20 backdrop-blur-lg`}
      >
        <div class="flex flex-col items-center text-center space-y-6">
          <div class="rounded-full p-3 bg-sky-900/20 shadow-sm">
            {statusIcons[status()]}
          </div>

          <div class="space-y-2">
            <h1 class="text-2xl font-bold text-neutral-100">
              <Switch>
                <Match when={status() === "loading"}>Verifying</Match>
                <Match when={status() === "ok"}>Verified</Match>
                <Match when={status() === "error"}>Verification Failed</Match>
              </Switch>
            </h1>
            <p class="text-neutral-150">message</p>
          </div>
          <Show when={status() !== "loading"}>
            <div class="pt-4">
              <a
                href="/"
                class="inline-flex items-center justify-center gap-2 px-6 py-3 border border-transparent text-base font-medium rounded-sm text-neutral-150 bg-sky-800 hover:bg-sky-700 focus:ring-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200"
              >
                <House />
                Return Home
              </a>
            </div>
          </Show>
        </div>
      </div>
    </div>
  );
}
